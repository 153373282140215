import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

class VendorEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            users_data: [],
            selected_user: {},
            users_list: [],

            // vendor data variables
            isChecked: false,
            type_of_vendor_data: [],
            type_of_vendor_list: [],
            vendorDetails: null,
            error: null,
            otherEmails: [],
            otherEmail: "",

        }
    }

    handleCheckboxChange = () => {
        this.setState(prevState => ({
            isChecked: !prevState.isChecked
        }));
    };

    componentDidMount() {
        const location_path = window.location.pathname;
        const userLoginType = localStorage.getItem('user_login_type');
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = token;
        axios.get(`${API_HOST}/${location_path}`)
            .then(response => {
                if (response.data.status === 200) {
                    const vendor_details = response.data.vendor_data;
                    const initialCheckedState = vendor_details[0].Status;
                    const vendorDetails = vendor_details[0];
                    const otherEmailsString = vendor_details[0].other_emails;
                    const otherEmailsArray = JSON.parse(otherEmailsString);
                    console.log(vendor_details, otherEmailsArray)
                    this.setState({
                        vendorDetails,
                        isChecked: vendorDetails.Status,
                        otherEmails: Array.isArray(otherEmailsArray) ? otherEmailsArray : [],
                    }, () => {
                        console.log('Updated state:', this.state);
                    });

                    console.log('Fetched vendor details:', vendorDetails.other_emails);
                }
                else if (response.data.status === 401) {
                    notify("Vendor Not Found", "error")
                    this.props.history.push('/vendors')

                } else {
                    console.error('Error fetching vendor details. Status:', response.status);
                }
            })
            .catch(error => {
                console.error('Error fetching vendor details:', error);
            });

        this.getTypeOfVendorData();

        if (
            this.state.user_data &&
            this.state.user_data.hasOwnProperty('is_admin') &&
            this.state.user_data.is_admin
        ) {
            this.getAllData();
        } else {

            this.props.history.push("/")
            setTimeout(() => {
                $('.loader').fadeOut('slow');
                notify("You are not allowed", 'warning');
            }, 1000);
        }
    }


    getAllData = () => {
        this.getUsersData()
    }

    getUsersData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var users_data = JSON.parse(response.data.users_data)
                    var current_user = users_data.filter((row, indx) => {
                        if (row.email == this.state.user_data.email) {
                            return row.is_admin
                        } else {
                            return false
                        }
                    })
                    if (current_user.length == 0) {
                        notify("You are not allowed", 'warning')
                        setTimeout(() => {
                            $('.loader').fadeOut('slow')
                            window.location.href = '/'
                        }, 3000)
                    }
                    var users_list = users_data.map((row, index) => {
                        return { "email": row.email, "first_name": row.first_name, "last_name": row.last_name }
                    })
                    this.setState({ "users_data": users_data, "users_list": users_list })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }

    getTypeOfVendorData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/vendor`,)
            .then(response => {
                if (response.status === 200) {
                    var type_of_vendor_data = response.data.vendor_type
                    this.setState({ "type_of_vendor_list": type_of_vendor_data })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {
                console.log(error_response)

            })
    }

    isValid = () => {
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if (!document.querySelector("#vendor_name").value || !document.querySelector("#type_of_vendor").value ||  !document.querySelector("#phone_number").value ) {
            var scrollDiv = ""
            if (!document.querySelector("#vendor_name").value) {
                const invoiceIncludeField = document.querySelector('#vendor_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#vendor_name').classList.add("error")
                scrollDiv = "vendor_name"
            }
            if (!document.querySelector("#phone_number").value) {
                const invoiceNumberField = document.querySelector('#phone_number');
                invoiceNumberField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#phone_number').classList.add("error")
                scrollDiv = "phone_number"
            }
            return { "status": false, "reason": "Please fill required field", "id": scrollDiv }
        }
        if (document.querySelector("#other_email").value && !this.isValidEmail(document.querySelector("#other_email").value)) {
            document.querySelector('#other_email').classList.add("error")
            var scrollDiv = "other_email"
            return { "status": false, "reason": "Please enter valid email.", "id": scrollDiv }
        }
        if (document.querySelector("#phone_number").value  && !this.isValidNumber(document.querySelector("#phone_number").value)) {
            document.querySelector('#phone_number').classList.add("error")
            var scrollDiv = "phone_number"
            return { "status": false, "reason": "Please enter valid mobile number.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_name").value && name_regexp.test(document.querySelector("#vendor_name").value))) {
            document.querySelector('#vendor_name').classList.add("error")
            var scrollDiv = "vendor_name"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#vendor_description").value && name_regexp.test(document.querySelector("#vendor_description").value))) {
            document.querySelector('#vendor_description').classList.add("error")
            var scrollDiv = "vendor_description"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        return { "status": true, "reason": "" }
    }

    isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    validatePhoneNumber = (e) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {

            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    };
    isValidNumber = (number) => {
        const regex = /^([6-9][0-9]{9})$/;
        return regex.test(number);
    };
    handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault(); // Block the non-numeric key press
        }
    };
    

    handleOtherEmailChange = (e) => {
        this.setState({ otherEmail: e.target.value });
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    };

    addOtherEmail = () => {
        const { otherEmails, otherEmail } = this.state;
        if (otherEmail && !this.isValidEmail(otherEmail)) {
            notify('Please enter valid email.', 'warning')
        } else {
            if (otherEmail.trim() !== '') {
                this.setState({ otherEmails: [...otherEmails, otherEmail], otherEmail: '' });
            }
        }
    };

    removeOtherEmail = (index) => {
        const { otherEmails } = this.state;
        const updatedEmails = [...otherEmails];
        updatedEmails.splice(index, 1);
        this.setState({ otherEmails: updatedEmails });
    };

    handleOtherEmailKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.addOtherEmail();
        }
    };

    handleOtherEmailBlur = () => {
        this.addOtherEmail();
    };


    handleChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    }


    handleDescripChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
        } else {
            inputElement.classList.remove("error");
        }
    }

    submitVendorData = (e) => {
        e.preventDefault()
        const vendor_name = document.getElementById("vendor_name").value
        const type_of_vendor = document.getElementById("type_of_vendor").value
        const vendor_description = document.getElementById("vendor_description").value
        const vendor_status = document.getElementById("status").checked
        const vendor_phone_number = document.getElementById('phone_number').value
        const other_emails = this.state.otherEmails
        var vendorData = {}
        vendorData["vendor_name"] = vendor_name
        vendorData["other_emails"] = other_emails
        vendorData["type_of_vendor"] = type_of_vendor
        vendorData["vendor_description"] = vendor_description
        vendorData["vendor_status"] = vendor_status
        vendorData['vendor_phone_number'] = vendor_phone_number
        console.log('update vendor data..... ', vendorData)
        console.log(other_emails, 'when data post other_emails')
        var response_isvalid = this.isValid()
        if (!response_isvalid.status) {
            notify(response_isvalid.reason, 'warning')
            setTimeout(function () {
                document.querySelector('#' + response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest"
                })
            }, 100)
            return false
        }
        else {
            $(".loader").css("display", "flex");
            const auth_token = localStorage.getItem("token")
            const requestData = {
                vendorData: vendorData,
                userData: this.state.user_data
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/vendor/${this.state.vendorDetails.Id}`, requestData)
                .then(response => {

                    if (response.status === 200 && response.data.success) {
                        notify(response.data.message, 'success');
                        setTimeout(() => {
                            window.location.href = '/vendors?workflow=ibd';
                        }, 2000)
                    }
                    else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                    // ErrorHandling(response)
                });
        }

    }

    render() {
        const { isChecked, showPassword, otherEmails, otherEmail } = this.state;
        console.log(otherEmails, otherEmail, 'opopop')
        console.log('otherEmails length:', otherEmails.length);

        return (
            this.state.vendorDetails && (
                <div className='container' style={{ "paddingBottom": "250px", "paddingTop": "25px" }}>
                    <div className='col-12'>
                        <div className="card vendor-card pb-3">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-sm-12 col-12 d-flex justify-content-between'>
                                        <h5 className="vendor-card-title" > <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={() => { window.location.href = "/vendors?workflow=ibd" }}><FaArrowLeft /></span><b>Update Vendor</b></h5>
                                        <div className='d-flex align-items-center'>
                                            <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={this.handleCheckboxChange}
                                                style={{ marginRight: '5px' }}
                                                id='status'
                                            />
                                            <label className='form-label-text font-15'>Active</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-4">
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12' >Vendor Name <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="vendor_name" id="vendor_name" type="text" defaultValue={this.state.vendorDetails.Name} onChange={(e) => this.handleChange(e, '')} />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Type Of Vendor <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            {this.state.type_of_vendor_list.length > 0 ? (
                                                <select className='ps-3 border-0 w-100 font-14 form-select pl-1 pt-1' name="type_of_vendor" id="type_of_vendor" defaultValue={this.state.vendorDetails.VendorType}>
                                                    {this.state.type_of_vendor_list.map((option, index) => (
                                                        <option key={index} value={option.vendor_type}>{option.vendor_type}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 mt-3'>
                                        <div className="form-group">
                                            <div className='form-label-text font-12'>Vendor Description/Notes</div>
                                            <textarea className="form-control input-text-description" name="type_of_vendor" style={{ borderRadius: '5px' }} id="vendor_description" defaultValue={this.state.vendorDetails.Notes} rows="3" onChange={(e) => this.handleDescripChange(e, '')}></textarea>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Other E-mails</div>
                                        <div className='login-text-box d-flex'>
                                            <input
                                                className='ps-3 border-0 w-100 font-14 form-control'
                                                name='other_email'
                                                id='other_email'
                                                type='text'
                                                value={otherEmail}
                                                onChange={this.handleOtherEmailChange}
                                                onKeyDown={this.handleOtherEmailKeyDown}
                                                onBlur={this.handleOtherEmailBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Phone Number <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box '>
                                            <input
                                                className='ps-3 border-0 w-100 font-14 form-control'
                                                name='phone_number'
                                                id='phone_number'
                                                type='text'
                                                defaultValue={this.state.vendorDetails.PhoneNumber}
                                                onChange={(e) => this.validatePhoneNumber(e,'')}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>


                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        {otherEmails.length > 0 ? (
                                            otherEmails.map((email, index) => (
                                                <div key={index} className='other-email-item d-flex align-items-center me-2 mb-2'>
                                                    <span>{email}</span>
                                                    <span className='email-remove-icon' onClick={() => this.removeOtherEmail(index)}>
                                                        <FaTimes />
                                                    </span>
                                                </div>
                                            ))
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                        <button className="cancel-button" type="button" onClick={() => { window.location.href = '/vendors?workflow=ibd' }}>Cancel</button>
                                        <button className="submit-button" type="button" onClick={(e) => { this.submitVendorData(e) }}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )


        )
    }
}
export default withRouter(VendorEdit);

