import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from '../../Settings.js'
import axios from 'axios';
import notify from '../CommonComponents/Notify';
import '../../Styles/userRoles.css';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';

class UserEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user_data: localStorage.getItem('user_data') ? JSON.parse(localStorage.getItem('user_data')) : {},
            users_data: [],
            designation_data_ui: [],
            sites_data_ui: [],
            plants_of_location_ui: [],
            departments_data_ui: [],
            designation: '',
            site_name: '',
            plant_name: '',
            department_name: '',
            userDetails: null,


        }
    }


    componentDidMount() {
        const location_path = window.location.pathname;
        const userLoginType = localStorage.getItem('user_login_type');
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = token;
        axios.get(`${API_HOST}/${location_path}`)
            .then(response => {
                if (response.data.status === 200) {
                    const user_details = response.data.user_data;
                    const userDetails = user_details[0];
                    const site_name = userDetails.site_name
                    console.log(site_name)
                    this.setState({
                        userDetails,
                        site_name: userDetails.site_name,
                        plant_name: userDetails.plant_name,
                        department_name: userDetails.department_name,
                        designation: userDetails.designation
                    }, () => {
                        if (this.state.userDetails && site_name !== '')  {
                            this.getPlantsOfLocation(site_name);
                        }
                    });
                }
                else if (response.data.status === 401) {
                    notify("User Not Found", "error")
                    this.props.history.push('/settings')

                } else {
                    console.error('Error fetching user details. Status:', response.status);
                }
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });
        if (
            this.state.user_data &&
            this.state.user_data.hasOwnProperty('is_admin') &&
            this.state.user_data.is_admin
        ) {
            this.getAllData();
        } else {

            this.props.history.push("/")
            setTimeout(() => {
                $('.loader').fadeOut('slow');
                notify("You are not allowed", 'warning');
            }, 1000);
        }
    }
    handleStatusChange = (event) => {
        this.setState({
            status: event.target.value
        })
    };

    getAllData = () => {
        this.getAllDesignations();
        this.getAllSitesList();
        this.getAllDepartments();
    }
    getAllDesignations = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/designations/`,)
            .then(response => {
                if (response.status === 200) {
                    var designation_data = response.data.data.designation_data
                    var designation_data_ui = designation_data.map((item, index) => {
                        return { label: item.name, value: item.id }
                    })
                    this.setState({ "designation_data": designation_data, "designation_data_ui": designation_data_ui })
                }
            })
    }
    getAllSitesList = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/all/sites/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var sites_data = response.data.data.sites_data
                    var sites_data_ui = sites_data.map((item, index) => {
                        return { label: item.name, value: item.name_slug }
                    })
                    this.setState({ "sites_data": sites_data, "sites_data_ui": sites_data_ui })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }
    getPlantsOfLocation = (site_name_slug) => {
        $('.loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/admin/get/plants/of/location/?site_name_slug=${site_name_slug}`,)
            .then(response => {
                if (response.status === 200) {
                    debugger;
                    var plants_of_location = response.data.data.plants_of_location
                    var plants_of_location_ui = plants_of_location.map((item, index) => {
                        return { label: item.plant.name, value: item.plant.id }
                    })
                    this.setState({
                        "plants_of_location_ui": plants_of_location_ui,

                    })
                }
                $('.loader').fadeOut('slow')
            }).catch(error_response => {

            })
    }
    getAllDepartments = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/departments/data/`,)
            .then(response => {
                if (response.status === 200) {
                    var departments_data = response.data.departments_data
                    var departments_data_ui = departments_data.map((item, index) => {
                        return { label: item.name, value: item.id }
                    })
                    this.setState({ "departments_data": departments_data, "departments_data_ui": departments_data_ui })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }
    handleDesignation = (event) => {
        this.setState({ designation: event.target.value })
    }
    handleSiteChange = (event) => {
        this.setState({
            site_name: event.target.value
        })
        this.getPlantsOfLocation(event.target.value)
    }
    handlePlantChange = (event) => {
        this.setState({ plant_name: event.target.value }, () => {
        });
    }
    handleDepartmentChange = (event) => {
        this.setState({ department_name: event.target.value})
    }

    getUsersData = () => {
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/get/all/users/list/`,)
            .then(response => {
                if (response.status === 200) {
                    var users_data = JSON.parse(response.data.users_data)
                    var current_user = users_data.filter((row, indx) => {
                        if (row.email == this.state.user_data.email) {
                            return row.is_admin
                        } else {
                            return false
                        }
                    })
                    if (current_user.length == 0) {
                        notify("You are not allowed", 'warning')
                        setTimeout(() => {
                            $('.loader').fadeOut('slow')
                            window.location.href = '/'
                        }, 3000)
                    }
                    var users_list = users_data.map((row, index) => {
                        return { "email": row.email, "first_name": row.first_name, "last_name": row.last_name }
                    })
                    this.setState({ "users_data": users_data, "users_list": users_list })
                    $('.loader').fadeOut('slow')
                }
            }).catch(error_response => {

            })
    }
    isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        console.log(emailRegex.test(email), 'check email format.....')
        return emailRegex.test(email);
    };

    isValidNumber = (number) => {
        const regex = /^([6-9][0-9]{9})$/;
        return regex.test(number);
    };

    validatePhoneNumber = (e) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {

            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    };
    handleKeyPress = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault(); // Block the non-numeric key press
        }
    };
    




    isValid = () => {
        var name_regexp = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
        if (!document.querySelector("#first_name").value  || !document.querySelector("#designation").value || !document.querySelector("#site_name").value || !document.querySelector("#department_name").value || !document.querySelector("#phone_number").value) {
            var scrollDiv = ""
            if (!document.querySelector("#first_name").value) {
                const invoiceIncludeField = document.querySelector('#first_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#first_name').classList.add("error")
                scrollDiv = "first_name"
            }
            if (!document.querySelector("#designation").value) {
                const invoiceIncludeField = document.querySelector('#designation');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#designation').classList.add("error")
                scrollDiv = "designation"
            }
            if (!document.querySelector("#department_name").value) {
                const invoiceIncludeField = document.querySelector('#department_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#department_name').classList.add("error")
                scrollDiv = "department_name"
            }
            if (!document.querySelector("#site_name").value) {
                const invoiceIncludeField = document.querySelector('#site_name');
                invoiceIncludeField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#site_name').classList.add("error")
                scrollDiv = "site_name"
            }
            if (!document.querySelector("#phone_number").value) {
                const invoiceNumberField = document.querySelector('#phone_number');
                invoiceNumberField.closest('.login-text-box').style.border = 'none';
                document.querySelector('#phone_number').classList.add("error")
                scrollDiv = "phone_number"
            }
            return { "status": false, "reason": "Please fill required field", "id": scrollDiv }
        }
        if ((document.querySelector("#first_name").value && name_regexp.test(document.querySelector("#first_name").value))) {
            document.querySelector('#first_name').classList.add("error")
            var scrollDiv = "first_name"
        }
        if ((document.querySelector("#designation").value && name_regexp.test(document.querySelector("#designation").value))) {
            document.querySelector('#designation').classList.add("error")
            var scrollDiv = "designation"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#site_name").value && name_regexp.test(document.querySelector("#site_name").value))) {
            document.querySelector('#site_name').classList.add("error")
            var scrollDiv = "site_name"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if ((document.querySelector("#department_name").value && name_regexp.test(document.querySelector("#department_name").value))) {
            document.querySelector('#department_name').classList.add("error")
            var scrollDiv = "department_name"
            return { "status": false, "reason": "Please provide valid information in input fields.", "id": scrollDiv }
        }
        if (document.querySelector("#phone_number").value  && !this.isValidNumber(document.querySelector("#phone_number").value)) {
            document.querySelector('#phone_number').classList.add("error")
            var scrollDiv = "phone_number"
            return { "status": false, "reason": "Please enter valid mobile number.", "id": scrollDiv }
        }
        return { "status": true, "reason": "" }
    }
    handleChange = (e, state_obj) => {
        const inputElement = e.currentTarget;
        if (inputElement.value === "") {
            inputElement.classList.add("error");
            inputElement.closest('.login-text-box').style.border = 'none';
        } else {
            inputElement.classList.remove("error");
            inputElement.closest('.login-text-box').style.border = '1px solid #C6D9EB';
        }
    }
    //   getInputValue = (id) => {
    //         const element = document.getElementById(id);
    //         return element ? element.value : '';
    //     };
    submitUserData = (e) => {
        e.preventDefault()
        const first_name = document.getElementById("first_name").value
        const last_name = document.getElementById("last_name").value
        const user_email = document.getElementById("user_email").value
        const user_phone_number = document.getElementById("phone_number").value
        const site_name = this.state.site_name
        const plant_name = this.state.plant_name
        const designation = this.state.designation
        const department_name = this.state.department_name
        // const user_password = this.getInputValue("user_password");
        // const confirm_user_password = this.getInputValue("confirm_user_password");
        const is_active = document.getElementById("status").value
        var user_Data = {}
        user_Data["first_name"] = first_name
        user_Data["last_name"] = last_name
        user_Data["user_email"] = user_email
        user_Data["is_active"] = is_active
        user_Data['site_name'] = site_name
        user_Data['plant_name'] = plant_name
        user_Data['designation'] = designation
        user_Data['department_name'] = department_name
        user_Data['user_phone_number'] = user_phone_number
        var response_isvalid = this.isValid()
        if (!response_isvalid.status) {
            notify(response_isvalid.reason, 'warning')
            setTimeout(function () {
                document.querySelector('#' + response_isvalid.id).scrollIntoView({
                    behavior: 'smooth', block: "center", inline: "nearest"
                })
            }, 100)
            return false
        }
        else {
            $(".loader").css("display", "flex");
            const auth_token = localStorage.getItem("token")
            const requestData = {
                userData: user_Data
            };
            axios.defaults.headers.post['Authorization'] = auth_token
            axios.post(`${API_HOST}/user/${this.state.userDetails.id}`, requestData)
                .then(response => {

                    if (response.status === 200 && response.data.success) {
                        notify(response.data.message, 'success');
                        setTimeout(() => {
                            window.location.href = '/settings/';
                        }, 2000)
                    }
                    else {
                        notify(response.data.message, 'error');
                    }
                    $('.loader').fadeOut('slow');
                }).catch(response => {
                    $('.loader').fadeOut('slow')
                    // ErrorHandling(response)
                });
        }

    }

    render() {
        return (
            this.state.userDetails && (
                <div className='container' style={{ "paddingBottom": "250px", "paddingTop": "25px" }}>
                    <div className='col-12'>
                        <div className="card vendor-card pb-3">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-sm-12 col-12 d-flex justify-content-between'>
                                        <h5 className="vendor-card-title" > <span className="mr-3 arrow-left" style={{ fontSize: '1.0rem', marginRight: '17px' }} onClick={() => { window.location.href = "/settings/" }}><FaArrowLeft /></span><b>Update User</b></h5>
                                    </div>
                                </div>
                                <div className="row mb-3 mt-4">
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12' >First Name<span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="first_name" id="first_name" type="text" defaultValue={this.state.userDetails.first_name} onChange={(e) => this.handleChange(e, '')} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12' >Last Name</div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="last_name" id="last_name" type="text" defaultValue={this.state.userDetails.last_name} onChange={(e) => this.handleChange(e, '')} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12' >Email <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="user_email" id="user_email" disabled={true} type="email" defaultValue={this.state.userDetails.user_email} onChange={(e) => this.handleChange(e, '')} style={{ backgroundColor: '#D3D3D3' }} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12' >Status <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            <select
                                                className='ps-3 border-0 w-100 font-14 form-control'
                                                name="status"
                                                id="status"
                                                defaultValue={this.state.userDetails.status ? "1" : "0"}
                                                onChange={this.handleStatusChange}
                                            >
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Employee Number</div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="employe_number" id="employee_number" type="number" defaultValue={this.state.userDetails.employee_number} onChange={(e) => this.handleChange(e, '')} />
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Phone Number<span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div className='login-text-box'>
                                            <input className='ps-3 border-0 w-100 font-14 form-control' name="phone_number" id="phone_number" type="text" defaultValue={this.state.userDetails.phone_number} onChange={(e) => this.validatePhoneNumber(e,'')} onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-2'>
                                        <div className='form-label-text font-12'>Designation <span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div  className='login-text-box'>
                                            <select
                                                id="designation"
                                                className='w-100 form-control'
                                                onChange={this.handleDesignation}
                                                value={this.state.designation}
                                                name="designation"
                                                style={{ height: '35px' }} 
                                            >
                                                <option value="">Select Designation</option>
                                                {this.state.designation_data_ui.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-2'>
                                        <div className='form-label-text font-12'>Site<span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div  className='login-text-box'>
                                            <select
                                                id="site_name"
                                                className='w-100 form-control'
                                                onChange={this.handleSiteChange}
                                                value={this.state.site_name}
                                                name="site_name"
                                                style={{ height: '35px' }} 
                                            >
                                                <option value="">Select Site</option>
                                                {this.state.sites_data_ui.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Plant</div>
                                        <div>
                                            <select
                                                id="plant"
                                                className='w-100 form-control'
                                                onChange={this.handlePlantChange}
                                                value={this.state.plant_name}
                                                name="plant"
                                                style={{ height: '35px' }} 
                                            >
                                                <option value="">Select Plant</option>
                                                {this.state.plants_of_location_ui.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3'>
                                        <div className='form-label-text font-12'>Department<span style={{ color: 'red', fontSize: '15px' }}>*</span></div>
                                        <div  className='login-text-box'>
                                            <select
                                                id="department_name"
                                                className='w-100 form-control'
                                                onChange={this.handleDepartmentChange}
                                                value={this.state.department_name}
                                                name="department_name"
                                                style={{ height: '35px' }} 
                                            >
                                                <option value="">Select Department</option>
                                                {this.state.departments_data_ui.map(option => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div id='vendor-button-container' className="d-flex d-md-flex justify-content-md-end">
                                        <button className="cancel-button" type="button" onClick={() => { window.location.href = '/settings/' }}>Cancel</button>
                                        <button className="submit-button" type="button" onClick={(e) => { this.submitUserData(e) }}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )


        )
    }
}
export default withRouter(UserEdit);