import React, { Component } from 'react';
import $ from 'jquery'
import { API_HOST } from "../../Settings"
import axios from 'axios';
import VendorPageHeader from './VendorPageHeader.js';
import InvoiceLeftStatus from './InvoiceListLeftStatus.js';
import InvoiceListRequest from './InvoiceListRequest.js';
import notify from '../CommonComponents/Notify';
import { withRouter } from 'react-router-dom';



class InvoiceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "all",
            filterRequestData: [],
            invoiceListData: [],
            is_admin: false,
            invoice_url: '',
            reference_number: '',
            total_include_gst_amount: '',

        }
    }
    componentDidMount() {
        const notificationBlock = document.querySelector(".notification-Block");
        const userProfile = document.querySelector(".user-profile");

        if (notificationBlock) {
            notificationBlock.classList.add("d-none");
        }

        if (userProfile) {
            userProfile.classList.add("d-none");
        }

        axios.defaults.headers.common['Authorization'] = localStorage.getItem('token');
        const roles = JSON.parse(localStorage.getItem('roles'));
        const userLoginType = localStorage.getItem('user_login_type');

        if (userLoginType === 'rallis') {
            if (roles && (roles.length === 0 || !roles.includes("view-ibd"))) {
                const auth_token = localStorage.getItem("token");
                const userData = localStorage.getItem("user_data");
                const logoutEndpoint = '/logout/';

                axios.defaults.headers.common['Authorization'] = auth_token;

                axios.get(API_HOST + logoutEndpoint, {
                    params: {
                        user_data: userData
                    }
                }).then(response => {
                    if (response.status === 200) {
                        localStorage.clear();
                        notify("You don't have permission to view.", 'error');
                        this.props.history.push("/login");
                        return;
                    }
                });
            } else {
                this.setState({ invoice_url: 'invoices' }, () => {
                    this.fetchInvoiceData();
                });
            }

        }
        else {

            this.setState({ invoice_url: 'invoices' }, () => {
                this.fetchInvoiceData();
            });

        }

    }

    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const workflow = urlParams.get('workflow');
        if (workflow) {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }

    fetchInvoiceData() {
        var userData = localStorage.getItem('user_data');
        axios.get(`${API_HOST}/${this.state.invoice_url}`, {
            params: {
                user_data: userData
            }
        })
            .then(response => {
                console.log(response.data.invoice_data, 'invoice dataa..', response.data.invoice_total_exclude_gst_amount, 'invoice_total_exclude_gst_amount', response.data.invoice_total_include_gst_amount, 'invoice_total_include_gst_amount');
                if (response.status === 200) {
                    this.setState({
                        invoiceListData: response.data.invoice_data,
                        filterRequestData: response.data.invoice_data,
                        reference_number:response.data.reference_number,
                        total_include_gst_amount: response.data.invoice_total_include_gst_amount

                    });
                    $('.loader').fadeOut('slow');
                }
            })
            .catch(response => {
                if (response.response.status === 401) {
                    localStorage.clear();
                }
                $('.loader').fadeOut('slow');
                // ErrorHandling(response)
            });
    }

    calInvoiceTotalValue(calTotalValue) {
        // let invoice_total_exclude_gst_amount = 0;
        let invoice_total_include_gst_amount = 0;
    
        calTotalValue.forEach((count, index) => {
            // const excludeValue = parseFloat(count.invoice_total_value_exclude_gst, 10);
            const includeValue = parseFloat(count.invoice_total_value_include_gst, 10);
            // if (!isNaN(excludeValue)) {
            //     invoice_total_exclude_gst_amount += excludeValue;
            // }
            if (!isNaN(includeValue)) {
                invoice_total_include_gst_amount += includeValue;
            }
        });
            this.setState({
            // total_exclude_gst_amount: parseFloat(invoice_total_exclude_gst_amount),
            total_include_gst_amount: parseFloat(invoice_total_include_gst_amount)
        });
    }
    

    changeTabHandler = (e, tab) => {
        e.preventDefault();
        if (tab === "all") {
            this.setState({
                filterRequestData: this.state.invoiceListData,
                tab: "all"
            });
            this.calInvoiceTotalValue(this.state.invoiceListData)
        } else if (tab === 'pending') {
            var requestData = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "pending";
            });
            this.calInvoiceTotalValue(requestData)
            this.setState({
                filterRequestData: requestData,
                tab: "pending"
            });

        } else if (tab === 'rejected') {
            var requestDataRej = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "rejected";
            });
            this.calInvoiceTotalValue(requestDataRej)
            this.setState({
                filterRequestData: requestDataRej,
                tab: "rejected" // Corrected the tab value to "rejected"
            });
        } else if (tab === 'processed') {
            var requestDataPrcd = this.state.invoiceListData.filter((requestObj, index) => {
                return requestObj.status.toLowerCase() === "processed";
            });
            this.calInvoiceTotalValue(requestDataPrcd)
            this.setState({
                filterRequestData: requestDataPrcd,
                tab: "processed"
            });
        }
    }


    clickTabHandler = (e) => {
        e.preventDefault();
        $('.loader').fadeIn();
        var search_text = document.querySelector("#search_text").value;
        var status = [];
        var vendorTypes = [];
        var selectedStages = [];

        // Loop through checked checkboxes for status
        document.querySelectorAll('input[name="status"]:checked').forEach((checkbox) => {
            status.push(checkbox.value);
        });

        // Loop through checked checkboxes for vendor type
        document.querySelectorAll('input[name="vendorType"]:checked').forEach((checkbox) => {
            vendorTypes.push(encodeURIComponent(checkbox.value));
        });

        document.querySelectorAll('input[name="selectedStage"]:checked').forEach((checkbox) => {
            selectedStages.push(checkbox.value);
        });

        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        var userData = localStorage.getItem('user_data')
        const userLoginType = localStorage.getItem('user_login_type');
        console.log(userLoginType, 'userLoginType')

        let invoiceUrl = `/all/invoices`;

        axios.get(`${API_HOST}${invoiceUrl}`, {
            params: {
                status: status.join(','),
                vendorTypes: vendorTypes.join(','),
                selectedStages: selectedStages.join(','),
                search_text: search_text,
                user_data: userData
            }
        })
            .then(response => {
                if (response.status === 200) {
                    console.log('excuted')
                    var status = response.data.search_status;
                    var vendorTypes = response.data.search_vendor_types;
                    var selectedStagesResponse = response.data.search_selected_stages;
                    var invoiceData = response.data.invoice_data;
                    console.log('filter_invoice', invoiceData)
                    this.setState({
                        filterRequestData: invoiceData,
                        invoiceListData:invoiceData,
                        reference_number:response.data.reference_number,
                        total_include_gst_amount: response.data.invoice_total_include_gst_amount,
                        tab: "all"
                    }, () => {
                    console.log(this.state.filterRequestData);
                    // console.log(this.state.total_exclude_gst_amount)
                    ;console.log(this.state.total_include_gst_amount)
                    });
                    $('.loader').fadeOut('slow');
                    // Update status checkboxes
                    var status_list = ["Processed", "Pending", "Rejected"];
                    for (var j = 0; j < status_list.length; j++) {
                        if (status.includes(status_list[j])) {
                            $("#" + status_list[j]).prop("checked", true);
                        } else {
                            $("#" + status_list[j]).prop("checked", false);
                        }
                    }

                    // Update vendor type checkboxes
                    var vendor_type_list = ['Transporter', 'CHA', 'Forwarder', 'Shipping Line(Direct)', 'Inspection Agency', 'Others'];
                    for (var k = 0; k < vendor_type_list.length; k++) {
                        (function () {
                            var currentVendor = vendor_type_list[k].trim();
                            var isVendorMatch = vendorTypes.some(function (vendor) {
                                return vendor.trim() === currentVendor;
                            });

                            // Use attribute selector to find the checkbox by ID
                            var $checkbox = $('[id="' + currentVendor + '"]');

                            // Check or uncheck the checkbox based on the result
                            $checkbox.prop("checked", isVendorMatch);
                        })();
                    }

                   

                    var stage_list = ['Approver 1', 'Approver 2', 'Approver 3', 'Approver 4', 'Approver 5', 'Final Approver'];
                    for (var m = 0; m < stage_list.length; m++) {
                        (function () {
                            var currentStage = stage_list[m].trim();
                            var isStageSelected = selectedStagesResponse.some(function (stage) {
                                return stage.trim() === currentStage;
                            });
                    
                            var $checkbox = $('[id="' + currentStage + '"]');
                    
                            $checkbox.prop("checked", isStageSelected);
                        })();
                    }

                }
            })
            .catch(response => {
                // ErrorHandling(response)
            });
    };

    render() {
        return (
            <div className='container vendor-dashboard-container'>
                <VendorPageHeader currentPage="invoice" clickTabHandler={this.clickTabHandler} />
                <div className='row main-dashboard vendor-main-dashboard mb-3'>
                    <div className='col-lg-2 col-md-2 col-12'>
                        <InvoiceLeftStatus thisData={this.state} clickTabHandler={this.clickTabHandler} />
                    </div>
                    <div className='col-lg-10 col-md-10 col-12 mt-2'>
                        <InvoiceListRequest thisData={this.state} changeTabHandler={this.changeTabHandler} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(InvoiceList);
