import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PasswordImage from "../../images/Password.svg"
import { API_HOST } from '../../Settings.js';
import "../../Styles/brioStyle.css"
import notify from '../CommonComponents/Notify';
import $ from 'jquery';
import Logo from "../../images/logo.svg";

class OtpVerification extends Component {

    componentDidMount() {
        $('.loader').fadeOut('slow');
        $('body').addClass('body-background-image');
        if (this.props.location.state ? this.props.location.state.params.message : '') {
            notify(this.props.location.state.params.message, 'information');
        }
    }

    componentWillUnmount() {
        $('body').removeClass('body-background-image');
    }

    otpSubmit = (event) => {
        event.preventDefault();
        const otp_code = document.getElementById("otp_code").value;
        const user_id = localStorage.getItem('user_id');
        const login_info_id = localStorage.getItem('login_info_id');

        if (otp_code) {
            $('.loader').fadeIn();
            axios({
                method: 'post',
                url: API_HOST + '/verify/otp/',
                data: { "otp_code": otp_code, "user_id": user_id, "login_info_id": login_info_id},
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            }).then((response) => {
                if (response.data.success) {
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('user_data', response.data.user_data)
                    localStorage.setItem('roles', response.data.roles)
                    localStorage.setItem('user_login_type', response.data.user_login_type)
                    window.location.href = '/invoices';
                    
                } else {
                    notify(response.data.message, 'error');
                }
                $('.loader').fadeOut('slow');
            })
            .catch((error) => {
                $(".loader").fadeOut('slow');
                notify("Failed to verify OTP, please try again.", 'error');
            });
        } else {
            var error_msg = 'Please enter the OTP';
            document.getElementById("error_msg").appendChild(document.createTextNode(error_msg));
            setTimeout(() => {
                document.getElementById("error_msg").innerText = '';
            }, 10000);
        }
    }

    render() {
        return (
            <div className="container-fluid background-transparent">
                <div className="loader"><div className="spin-loader"></div></div>
                <div className="row pt-5 pb-5 p-4">
                    <div className="col-lg-7 col-md-6 col-12 d-flex justify-content-center align-items-center">
                        <img className='company-logo display-none' src={Logo} alt="Company Logo" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                        <form onSubmit={this.otpSubmit} className="otp-form">

                            <div className='signup-block pe-5 ps-5 pb-5 mt-2'>
                                <div className='text-black text-center fontWeight-bold font-24'>OTP Verification</div>
                                <div className='mt-4 otp-details'>
                                    <p id="error_msg" className="text-danger"></p>
                                    <p className="enter-otp-title">Enter OTP sent to registered Mobile Number</p>
                                    <div className='mb-3'>
                                        <div className='secoundary-text font-12'>Enter OTP</div>
                                        <div className='position-relative w-100 otp-text-box'>
                                            <img className='position-relative' src={PasswordImage} alt='otp icon' />
                                            <input className='ps-3 border-0 w-100 font-14' type="text" id="otp_code" placeholder="Enter OTP" />
                                        </div>
                                    </div>
                                    <div className='mt-5 w-100'>
                                        <button className='black-btn w-100' style={{ height: "37px" }} type="submit">Verify OTP</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OtpVerification);