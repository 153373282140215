import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Collapse } from 'bootstrap';
import SideMenu from '../../CommonComponents/SideMenu'
import { BsFileEarmarkPdf } from "react-icons/bs";
import SubTaskSideMenu from './SubTaskSideMenu';
import { IoIosArrowDropup, IoIosArrowDropdown } from 'react-icons/io';
import { API_HOST } from '../../../Settings';
import $, { event } from 'jquery';
import Attachment from '../../ConsentComponents/Attachment';
import ViewAttachmentsTask from '../../ConsentComponents/ViewAttachmentsTask';
import notify from '../../CommonComponents/Notify';
import { FaPlusCircle } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { VscTasklist } from "react-icons/vsc";
import { BsListTask } from "react-icons/bs";
import ApprovedIcon from '../../../images/approvedIcon.svg'
import { BsClock } from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { TbClock } from "react-icons/tb";
import EditSubtask from "./EditSubtask";
import { MdTask } from "react-icons/md";
import CommentTask from '../../ConsentComponents/CommentTask';


class PlantMOCLeadAndExecutionSubtask extends Component {
    constructor(props) {
        super(props)
        this.state =
        {
            requestID: this.props.match.params.requestID,
            toggle: false,
            currentStage: {},
            workflowData: [],
            collapseStags: {},
            dynamicompenents: [],
            has_pending_ask_more_request_for_sidemenu: false,
            has_pending_ask_more_request: false,
            subtask_document_number: "",
            is_skipped: false,
            assignee:'',
            user_roles: {},
            selectedDepartments: [],
            attachments: [
                {
                    attachment: "",
                    filename: "",
                    file_size: 0,
                    signed_url: "",
                    attachment_filename: ""
                }
            ],
            WorkflowAttachments: [],
            viewComments :[],
            subtasks: [],
            maintask:[],
            document_number:'',
            subTaskId:'',
            unique_combine: 'sub',
            task_status:'pending',
            main_task_status:'',
            description:'',
            main_task_id :'',
            assignee_id:'',
            is_plant_moc_lead:false,
            user_info : false,
        }
    }


    componentDidMount() {
        document.querySelector(".notification-Block")?.classList.add("d-none")
        document.querySelector(".user-profile")?.classList.add("d-none")
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        // this.getAllData()
        const urlParts = window.location.pathname.split('/');
        const taskId = urlParts[urlParts.length - 1];
        const document_number = urlParts[urlParts.length - 2];
        this.setState({'document_number':document_number})
        $('.card-loader').fadeIn()
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
        axios.get(`${API_HOST}/execution/sub/task/${taskId}/`,)
            .then(response => {
                debugger;
                var main_task = response.data.main_task
                var assigne = main_task[0]
                this.setState({'assignee':assigne.assignee,'assignee_id':assigne.assignee_id,"main_task_status":main_task[0].status,'main_task_id':main_task[0].id,'is_plant_moc_lead':main_task[0].is_plant_moc_lead,'description':main_task[0].description})
                var sub_task = response.data.sub_tasks
                this.setState({'maintask':main_task,'subtasks':sub_task})    
            })

    }

    // getAllData = () => {
    //     $('.card-loader').fadeIn()
    //     axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
    //     axios.get(`${API_HOST}/workflow/moc/request/${this.props.DocumentNumber}/stage/${this.props.StageName}/?component_name=${this.props.ComponentName}`,)
    //         .then(response => {
    //             if (response.status === 200) {
    //                 this.setState({
    //                     WorkflowData: response.data.data.workflow_stage_info,
    //                     WorkflowComponent: response.data.data.workflow_stage_info.components[0],
    //                     WorkflowSignatures: response.data.data.workflow_stage_info.components[0].workflow_signatures,
    //                     enable_signing: response.data.data.workflow_stage_info.components[0].enable_signing,
    //                     enable_approval: response.data.data.workflow_stage_info.components[0].enable_approval,
    //                     is_approved: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
    //                         response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Approved" ? true : false,
    //                     WorkflowAttachments: response.data.data.workflow_stage_info.components[0].workflow_attachments,
    //                     is_rejected: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
    //                         response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
    //                     show_plant_moc_opinion_card: response.data.data.workflow_stage_info.components[0].workflow_action_logs[0] &&
    //                         response.data.data.workflow_stage_info.components[0].workflow_action_logs[0].status == "Rejected" ? true : false,
    //                     plant_moc_opinion: response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0] ?
    //                         response.data.data.workflow_stage_info.components[0].wf_plant_moc_lead_review[0].action.name : "",
    //                     ask_for_more_history: response.data.data.ask_for_more_history,
    //                     // userComponentPermissions: response.data.data.workflow_stage_info.components[0].user_component_permissions
    //                 })
    //                 // this.renderDynamicComponent()
    //             }

    //         }).catch(response => {
    //             // ErrorHandling(response)
    //         });
    //     $('.card-loader').fadeOut('slow')
    // }

    handleUpdateAttachmentList = (attachment_obj, idx) => {
        console.log(this.state.attachments,'attachments')
        var attachments_list = this.state.attachments
        attachments_list[idx] = attachment_obj;
        this.setState({ attachments: attachments_list })

    };
    handleChange = (e) =>{
        this.setState({"description":e.target.value})
    }
    handleAddAttachmentButton = (e) => {
        let attachment_obj = {
            attachment: "",
            filename: "",
            file_size: 0,
            signed_url: "",
            attachment_filename: ""
        }
        var attachments_list = this.state.attachments
        attachments_list.push(attachment_obj);
        this.setState({ attachments: attachments_list })
    };
    handleDeleteAttachmentButton = (e, idx) => {
        var attachments_list = this.state.attachments
        attachments_list.splice(idx, 1);
        this.setState({ attachments: attachments_list })
    }
    removeWfAttachment =(event, id_of_wf_attachment)=>{
        event.preventDefault()
        var user_resp = window.confirm("Are you sure, you want to delete the attachment?")
        if(user_resp){
            $('.card-loader').fadeIn('')
            var attachment_data = {
                wf_attachment_id:id_of_wf_attachment,
            }
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
            axios.post(`${API_HOST}/execution/task/${this.state.subTaskId}/attachment/delete/`, attachment_data)
            .then(response => {
                if (response.status === 200) {
                    notify('Attachment deleted successfully','success')
                    this.handleTaskSubTaskData(this.state.subTaskId)
                }

            }).catch(response => {
                // ErrorHandling(response)
                $('.card-loader').fadeOut('slow')
            });
        }
        
    }
    attachmentSubmitHandler = (e, attachment_obj, idx) => {
        e.preventDefault()
        const urlParts = window.location.pathname.split('/');
        const taskId = urlParts[urlParts.length - 1];
        var subTaskId=this.state.subTaskId;
        const attachment_data = {
            model:"workflow_attachments",
            data: [
                {
                    sub_task_id: subTaskId,
                    file_name: attachment_obj.attachment_filename,
                    signed_url:attachment_obj.signed_url,
                }
            ]
        };
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')      
        axios.post(`${API_HOST}/execution/sub/task/${taskId}/`, attachment_data)
        .then(response => {
            if (response.status === 200) {
                notify('Attachment saved successfully','success')
                var file_name = response.data.new_attachment.file_name;
                var created_date = response.data.new_attachment.created_date;
                var signed_url = response.data.new_attachment.signed_url;
               const new_attachment_obj = {
                file_name: file_name,
                created_date : created_date,
                signed_url:signed_url,
            };
            console.log(new_attachment_obj)
            let attachments = [...this.state.WorkflowAttachments];
            attachments.push(new_attachment_obj); 
            console.log(attachments,'attachhhhh...')
            this.setState({ WorkflowAttachments: attachments });
            this.handleUpdateAttachmentList(new_attachment_obj, idx);
            }
    
        }).catch(response => {
            // ErrorHandling(response)
        });
        $('.card-loader').fadeOut('slow')
    }

    handleSaveAttachmentButton = (event, attachment_obj, idx) => {
        event.preventDefault();
        if (attachment_obj.attachment) {
            $('.loader').fadeIn()
            let form_data_django = new FormData();
            form_data_django.append('attachment', attachment_obj.attachment);
            form_data_django.append('size', attachment_obj.file_size);
            let url = `${API_HOST}/upload/file/in/azure/blob/storage`;
            axios.defaults.headers.post['Authorization'] = localStorage.getItem('token')
            axios.post(url, form_data_django,
            ).then(response => {
                if (response.data.success) {
                    // notify("File successfully uploaded",'information')
                    $('.loader').fadeOut('slow')
                    var attachment_obj = {
                        attachment_filename: response.data.filename,
                        signed_url: response.data.singed_url
                    }
                    this.attachmentSubmitHandler(event, attachment_obj, idx)
                } else {
                    notify(response.data.message, 'error')
                    $('.card-loader').fadeOut('slow')
                }

            }).catch(error_xlsx => {

            })

        } else {
            alert("Invalid files selected")
        }
    };

    componentDidUpdate() {
        let current_component_name_scroll = localStorage.getItem('current_component_name');

        if (current_component_name_scroll) {
            setTimeout(function () {
                let elementToScroll = document.querySelector('#' + current_component_name_scroll);

                if (elementToScroll) {
                    elementToScroll.scrollIntoView({
                        behavior: 'smooth', block: "center", inline: "nearest"
                    });

                    // Remove current_component_name from local storage
                    localStorage.removeItem('current_component_name');
                }
            }, 1000);
        }
    }

    // getUserPrivileges = () => {
    //     axios.defaults.headers.get['Authorization'] = localStorage.getItem('token')
    //     let privileges = JSON.stringify(['can_approve', 'can_reject', 'can_sign', 'can_comment', 'can_attach'])
    //     let component = this.state.current_component_name
    //     let params = { "document_number": this.state.DocumentNumber, "from_new": true, "request_type": "moc_request" }
    //     axios.get(`${API_HOST}/user/privileges/${component}/${privileges}/`, { params: params })
    //         .then(response => {
    //             if (response.status === 200) {
    //                 this.setState({
    //                     userPermissions: response.data.data,
    //                 })

    //             }
    //         }).catch(response => {
    //             // ErrorHandling(response)
    //         });
    // }
    handleTaskSubTaskData = (subtaskId) => {
        $('.card-loader').fadeIn();
        this.setState({
            WorkflowAttachments: [],
            viewComments: [],
        });
        axios.defaults.headers.get['Authorization'] = localStorage.getItem('token');
        axios.get(`${API_HOST}/execution/task/subtask/${subtaskId}/`)
            .then(response => {
                debugger;
                const data = response.data.data;
                this.setState({"task_status":data.status,'user_info':data.user_info})
                let attachments = [];
                let comments = [];
                if (data.attachments) {
                    try {
                        attachments = JSON.parse(data.attachments);
                    } catch (error) {
                        console.error('Failed to parse attachments:', error);
                    }
                }
                if (data.comments) {
                    try {
                        comments = JSON.parse(data.comments);
                    } catch (error) {
                        console.error('Failed to parse comments', error);
                    }
                }
                this.setState({
                    WorkflowAttachments: attachments,
                    viewComments: comments
                }, () => {
                    $('.card-loader').fadeOut(); // Hide loader after state update
                });
            })
            .catch(error => {
                console.error('Error fetching subtask data:', error);
                // Hide loader in case of failure
                $('.card-loader').fadeOut();
            });
    };
    
    

    updateSideMenu = (has_pending_ask_more_request_for_sidemenu) => {
        this.setState({ has_pending_ask_more_request_for_sidemenu: has_pending_ask_more_request_for_sidemenu })
    }
    
    handleSubtaskCollapse = (e, subtaskId) => {
        e.preventDefault();
        this.setState({ 'subTaskId': subtaskId, collapseStags: { ...this.state.collapseStags, [subtaskId]: !this.state.collapseStags[subtaskId] } });
      
        const allCollapses = document.querySelectorAll('.collapse');
        const clickedCollapse = document.getElementById(`collapse_${subtaskId}`);
        allCollapses.forEach(collapse => {
          const collapseInstance = Collapse.getOrCreateInstance(collapse, { toggle: false }); // Use Collapse.getOrCreateInstance for better compatibility
          if (collapse !== clickedCollapse && collapseInstance._isShown) {
            collapseInstance.hide();
          }
        });
        const clickedCollapseInstance = Collapse.getOrCreateInstance(clickedCollapse, { toggle: false });
        clickedCollapseInstance.show();
        this.handleTaskSubTaskData(subtaskId);
      };

    handleSubmit = () => {
    };

    handleMarkComplete = (e) =>{
        e.preventDefault()
        $('.card-loader').fadeIn();
        const urlParts = window.location.pathname.split('/');
        const taskId = urlParts[urlParts.length - 1];
        var sub_taskId = this.state.subTaskId
        if (sub_taskId === ''){
            sub_taskId= taskId
        }
        var task_data = {
            sub_task_id:sub_taskId,
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(`${API_HOST}/execution/task/sub/task/mark/complete/`,task_data)
            .then(response => {
                if(response.status === 200){
                    if(response.data.pending_tasks_count === 0){
                    notify(response.data.message,'success');
                    setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 1000)
                }
                else{
                    notify(response.data.pending_tasks_count+" "+'Subtasks are pending','warning');
                }
                }
            })
    }
    handleUpdateDescription = () =>{
        $('.card-loader').fadeIn();
        debugger;
        var description_data = {
            description:this.state.description,
            main_task_id:this.state.main_task_id,
        }
        axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
        axios.post(`${API_HOST}/update/task/details/`,description_data)
            .then(response => {
                if(response.status === 200){
                    notify(response.data.message,'success');
                    setTimeout(()=>{
                        $('.loader').fadeOut('slow')
                        window.location.reload();
                    }, 1000)
                }
            })
    }
   

    render() {
        if (this.state.subtask_document_number) {
            var url = "/moc/subtask/request/" + this.state.requestID + "/" + this.state.subtask_document_number
        } else {
            var url = "/create/subtask/request/" + this.state.requestID
        }
        var access_type = 'create'
        return (
            <div className='container sub-task-container'>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 side-nav-block">
                        <div className="card sticky-card ">
                            <div className="card-body sticky-body">
                                <SubTaskSideMenu subtasks={this.state.subtasks}  maintask={this.state.maintask}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-12 main-contant-block">
                        <div className='mt-2 mb-2 d-flex justify-content-between align-items-center'>
                            <div className='d-flex justify-content-start align-items-center'>
                                <h5 className='green-title-color' style={{ marginRight: '22px' }}>
                                    <a href="#" style={{ color: '#7cd721' }}>{this.state.document_number}</a>
                                </h5>
                                <div className='view-task-name'>
                                    <FaUserCircle style={{ fontSize: '22px' }} />
                                    <p>{this.state.assignee}</p>
                                </div>
                            </div>
                            <div className='mb-2 d-flex'>
                                <EditSubtask 
                                main_task_status = {this.state.main_task_status}
                                document_number = {this.state.document_number}
                                main_task_id = {this.state.main_task_id}
                                assignee_id = {this.state.assignee_id}
                                is_plant_moc_lead = {this.state.is_plant_moc_lead}
                                />
                                {this.state.main_task_status === 'pending' && this.state.user_info &&  (
                                    <button className="btn btn-dark btn-w-110" style={{ paddingTop: "6px" }} onClick={this.handleMarkComplete}>Mark complete</button>
                                )}
                            </div>
                        </div>

                        {this.state.maintask.map((subtask, index) => (
                            <div className="card mb-3 butterfly-card" id={`subtask_${subtask.id}`} key={`subtask_${subtask.id}`}>
                                <div className="card-header stage-card-header border-bottom" onClick={e => this.handleSubtaskCollapse(e, subtask.id)}>
                                    <div className=''>
                                        <MdTask style={{ fontSize: '24px', color: '#4e5168', marginRight: '2px' }} />
                                        <span className='stage-title'>{subtask.title} - </span> <span style={{color:'rgb(68 167 37)'}}> {subtask.document_number}</span>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center text-right'>
                                        {subtask.status === 'pending' ? (
                                            <span className="d-flex align-items-center" style={{ marginRight: '12px', padding: '8px' }}>
                                                <TbClock style={{ fontSize: '21px', color: 'orange' }} />
                                                <span className='position-relative ms-1'>{'Pending'}</span>
                                            </span>
                                        ) : subtask.status === 'completed' ? (
                                            <span className="approved-btn d-flex align-items-center" style={{ marginRight: '12px', padding: '8px' }}>
                                                <MdOutlineCheckCircleOutline style={{ fontSize: '21px' }} />
                                                <span className='position-relative ms-1' style={{ fontSize: '16px' }}>{'Approved'}</span>
                                            </span>
                                        ) : null}

                                        <span className='arrow-icon-span'>
                                            {this.state.collapseStags[subtask.id] === false ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
                                        </span>
                                    </div>
                                </div>

                                {/* Collapsible content */}
                                <div className={this.state.collapseStags[subtask.id] === false ? "card-body collapse show" : "card-body collapse"} id={`collapse_${subtask.id}`}>
                                    {/* Display subtask details */}
                                    {/* <div>Name: {subtask.subtaskName}</div> */}
                                    <label className="description-label">Task Name:</label>
                                    <div>
                                        <input
                                            type="text"
                                            name="taskName"
                                            className="edit-task-name-input mt-1 mb-1"
                                            value={subtask.title}
                                            placeholder="Write a task name"
                                            readOnly
                                            style={{ backgroundColor: '#f3f6fc', borderRadius: '2px', fontSize: '15px' }}

                                        />
                                    </div>
                                    <div className="description-container">
                                        <label className="description-label">Description:</label>
                                        <textarea
                                            type='text'
                                            name="description"
                                            className="description-input"
                                            defaultValue={subtask.description}
                                            onChange={this.handleChange}
                                            placeholder="Enter a task description"
                                            id='description-change'
                                            readOnly={this.state.main_task_status !== 'pending'}
                                            style={{ backgroundColor: this.state.main_task_status !== 'pending' ? '#f3f6fc' : 'white' }}
                                        />
                                    </div>
                                    <div className='heading-Text font-12 mb-3 d-flex'>
                                        {this.state.task_status === 'pending'  && this.state.user_info ? (
                                            <>
                                                Upload Attachments
                                                <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton} /></div>
                                            </>
                                        ) :""}
                                        </div>
                                        <div>
                                            <p><b>Attachments</b></p>
                                        {this.state.WorkflowAttachments.length > 0 ?
                                            <ViewAttachmentsTask removeWfAttachment={this.removeWfAttachment}
                                                key={this.state.unique_combine+'va'}
                                                enable_attach_delete={this.state.task_status === 'pending' && this.state.user_info}
                                                WorkflowAttachments={this.state.WorkflowAttachments} />
                                        :this.state.task_status === 'pending' ?'':'No Attachments'}
                                    </div>
                                    {this.state.task_status === 'pending' &&  this.state.user_info &&(
                                        this.state.attachments.map((each, index) => (
                                            <Attachment
                                                AttachmentIndex={index}
                                                AttachmentObj={each}
                                                key={this.state.unique_combine + 'attach' + index}
                                                handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                                handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                                handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                            />
                                        ))
                                    )}
                                    <div className='mt-4 mb-3 row'>
                                        <CommentTask
                                            key={this.state.unique_combine + 'cobj'}
                                            unique_combine={this.state.unique_combine}
                                            subTaskId={this.state.subTaskId}
                                            RequestType="moc-request"
                                            ref={this.commentRef}
                                            userPermissions={{ can_comment: true }}
                                            viewComments={this.state.viewComments}
                                            task_status = {this.state.task_status}
                                            user_info = {this.state.user_info}
                                        />
                                    </div>
                                    <div className='mt-4 mb-3 mr-3 row d-flex justify-content-end align-items-center'>
                                    {this.state.main_task_status === 'pending' &&  this.state.user_info  &&(
                                        <button className="btn btn-dark btn-w-110" style={{ paddingTop: "6px" }} onClick={this.handleUpdateDescription} >Update</button>
                                    )}
                                </div>
                                </div>
                            </div>
                        ))}


                        {this.state.subtasks.map((subtask, index) => (
                            <div className="card mb-3 butterfly-card" id={`subtask_${subtask.id}`} key={`subtask_${subtask.id}`}>
                                <div className="card-header stage-card-header border-bottom" onClick={e => this.handleSubtaskCollapse(e, subtask.id)}>
                                    <div className=''>
                                        <BsListTask style={{ fontSize: '21px', color: '#4e5168', marginRight: '7px' }} />
                                        <span className='stage-title'>{subtask.title} - </span> <span style={{color:'rgb(68 167 37)'}}> {subtask.document_number}</span>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-center text-right'>
                                        {subtask.status === 'pending' ? (
                                            <span className="d-flex align-items-center" style={{ marginRight: '12px', padding: '8px' }}>
                                                <TbClock style={{ fontSize: '21px', color: 'orange' }} />
                                                <span className='position-relative ms-1'>{'Pending'}</span>
                                            </span>
                                        ) : subtask.status === 'completed' ? (
                                            <span className="approved-btn d-flex align-items-center" style={{ marginRight: '12px', padding: '8px' }}>
                                                <MdOutlineCheckCircleOutline style={{ fontSize: '21px' }} />
                                                <span className='position-relative ms-1' style={{ fontSize: '16px' }}>{'Approved'}</span>
                                            </span>
                                        ) : null}

                                        <span className='arrow-icon-span'>
                                            {this.state.collapseStags[subtask.id] === false ? <IoIosArrowDropup /> : <IoIosArrowDropdown />}
                                        </span>
                                    </div>
                                </div>
                                {/* Collapsible content */}
                                <div className={this.state.collapseStags[subtask.id] === false ? "card-body collapse show" : "card-body collapse"} id={`collapse_${subtask.id}`}>
                                    <div className='heading-Text font-12 mb-3 d-flex'>
                                    {this.state.task_status === 'pending'  && this.state.user_info ? (
                                        <>
                                            Upload Attachments
                                            <div className="plus-circle"><FaPlusCircle onClick={this.handleAddAttachmentButton} /></div>
                                        </>
                                    ) :""}
                                    </div>
                                    <div>
                                        <p><b>Attachments</b></p>
                                    {this.state.WorkflowAttachments.length > 0 ?
                                        <ViewAttachmentsTask removeWfAttachment={this.removeWfAttachment}
                                            key={this.state.unique_combine+'va'}
                                            enable_attach_delete={this.state.task_status === 'pending' && this.state.user_info}
                                            WorkflowAttachments={this.state.WorkflowAttachments} />
                                    :this.state.task_status === 'pending' ?'':'No Attachments'}
                                </div>
                                {this.state.task_status === 'pending' &&  this.state.user_info &&(
                                    this.state.attachments.map((each, index) => (
                                        <Attachment
                                            AttachmentIndex={index}
                                            AttachmentObj={each}
                                            key={this.state.unique_combine + 'attach' + index}
                                            handleUpdateAttachmentList={this.handleUpdateAttachmentList}
                                            handleDeleteAttachmentButton={this.handleDeleteAttachmentButton}
                                            handleSaveAttachmentButton={this.handleSaveAttachmentButton}
                                        />
                                    ))
                                )}
                                    <div className='mt-4 mb-3 row'>
                                        <CommentTask
                                            key={this.state.unique_combine + 'cobj'}
                                            unique_combine={this.state.unique_combine}
                                            subTaskId={this.state.subTaskId}
                                            RequestType="moc-request"
                                            ref={this.commentRef}
                                            userPermissions={{ can_comment: true }}
                                            viewComments={this.state.viewComments}
                                            task_status = {this.state.task_status}
                                            user_info = {this.state.user_info}
                                        />
                                    </div>

                                    <div className='mt-4 mb-3 mr-3 row d-flex justify-content-end align-items-center'>
                                        {this.state.task_status === 'pending' && this.state.user_info && (
                                            <button className="btn btn-dark btn-w-110" style={{ paddingTop: "6px" }} onClick={this.handleMarkComplete}>Mark complete</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PlantMOCLeadAndExecutionSubtask)
