import React from 'react';
import { Router, Switch, Route, withRouter } from 'react-router-dom';
// import {PrivateRoute} from './PrivateRoutes'
// import Home from './pages/Home';
import history from './history';
import $ from 'jquery';
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import PrivateRoute from './PrivateRoutes';

import SignIn from "./Pages/User/SignIn"
import Dashboard from './Pages/Dashboard/Dashboard'
import NotificationsPage from './Pages/Notification/NotificationsPage';
import UserProfile from './Pages/User/UserProfile';
import ChangePassword from './Pages/User/ChangePassword';
import CreateRequest from './Pages/MOCRequest/CreateRequest';
import MOCRequest from './Pages/MOCRequest/ViewMOCRequest';
import Reports from './Pages/Dashboard/Reports';
import OauthRedirect from './Pages/User/OauthRedirect';
import CreateManpower from './Pages/ManpowerChange/CreateManpower';
import ViewManpowerRequest from './Pages/ManpowerChange/ViewManpowerRequest';
import CreateMOCSubtask from './Pages/MOCSubTaskRequest/CreateMOCSubtask';
import ViewMOCRequestSubtask from './Pages/MOCSubTaskRequest/ViewMOCRequestSubtask';
import ManageUserSettings from './Pages/User/ManageUserSettings';
import WorkFlows from './Pages/Dashboard/WorkFlows';
import VendorCreation from './Pages/IBD/VendorCreation';
import VendorEdit from './Pages/IBD/VendorEdit';
import VendorList from './Pages/IBD/VendorList';
import InvoiceCreation from './Pages/IBD/InvoiceCreation';
import InvoiceList from './Pages/IBD/InvoiceList';
import VendorSignIn from './Pages/IBD/VendorLogin';
import InvoiceView from './Pages/IBD/InvoiceView';
import TurnAroundTime from './Pages/Dashboard/TurnAroundTime';
import TurnAroundTimeIBD from './Pages/IBD/TurnAroundTimeIBD';
import './Styles/brioStyle.css';
import './Styles/loader.css';
import ForgotPassword from './Pages/User/ForgotPassword';
import ResetPassword from './Pages/User/ResetPassword';
import UserList from './Pages/Users/UserList';
import UserCreation from './Pages/Users/UserCreation';
import UserEdit from'./Pages/Users/UserEdit'
import PlantMOCLeadAndExecutionSubtask from './Pages/MOCRequest/6B Pages/PlantMOCLeadAndExecutionSubtask';
import OtpVerification from "./Pages/User/OtpVerification"
export default class App extends React.Component {
	render() {
		return (
			<React.Fragment>
				<Router history={history}>
					<Switch>
						<Route path="/login/"><SignIn /></Route>
						<Route path="/vendor/login/"><VendorSignIn /></Route>
						<Route path="/OtpVerification"><OtpVerification /></Route>
						<Route path="/oauth/login/redirect/" exact component={withRouter(OauthRedirect)} />
						<PrivateRoute exact path="/" component={WorkFlows}></PrivateRoute>
						<PrivateRoute exact path="/dashboard" component={Dashboard}></PrivateRoute>
						<PrivateRoute exact path="/dashboard/:workflow" component={Dashboard}></PrivateRoute>
						<PrivateRoute exact path="/notifications" component={NotificationsPage}></PrivateRoute>
						<PrivateRoute exact path="/profile" component={UserProfile}></PrivateRoute>
						<PrivateRoute exact path="/change/password" component={ChangePassword}></PrivateRoute>
						<PrivateRoute exact path="/create/request" component={CreateRequest}></PrivateRoute>
						<PrivateRoute exact path="/moc/request/:requestID/" component={MOCRequest}></PrivateRoute>
						<PrivateRoute exact path="/create/subtask/request/:documentID" component={CreateMOCSubtask}></PrivateRoute>
						<PrivateRoute exact path="/moc/subtask/request/:documentID/:requestID/" component={ViewMOCRequestSubtask}></PrivateRoute>
						<PrivateRoute exact path="/create/manpower/change/request" component={CreateManpower}></PrivateRoute>
						<PrivateRoute exact path="/manpower/change/request/:requestID/" component={ViewManpowerRequest}></PrivateRoute>
						<PrivateRoute exact path="/reports" component={Reports}></PrivateRoute>
						<PrivateRoute exact path="/settings/" component={UserList}></PrivateRoute>
						<PrivateRoute exact path="/user" component={UserCreation}></PrivateRoute>
						<PrivateRoute exact path="/user/:userID/" component={UserEdit}></PrivateRoute>
						<PrivateRoute exact path="/vendor" component={VendorCreation}></PrivateRoute>
						<PrivateRoute exact path="/vendors" component={VendorList}></PrivateRoute>
						<PrivateRoute exact path="/vendor/:vendorID/" component={VendorEdit}></PrivateRoute>
						<PrivateRoute exact path="/invoice" component={InvoiceCreation}></PrivateRoute>
						<PrivateRoute exact path="/invoices" component={InvoiceList}></PrivateRoute>
						<PrivateRoute exact path="/invoice/:id/" component={InvoiceView}></PrivateRoute>
						<PrivateRoute exact path="/turn/around/time" component={TurnAroundTime}></PrivateRoute>
						<PrivateRoute exact path="/turn/around/time/ibd" component={TurnAroundTimeIBD}></PrivateRoute>
						<PrivateRoute exact path="/roles/" component={ManageUserSettings}></PrivateRoute>
						<PrivateRoute exact path='/moc/request/subtask/:requestID/:taskID' component={PlantMOCLeadAndExecutionSubtask}></PrivateRoute>
						<Route exact path='/forgot/password/' component={ForgotPassword}></Route>
						<Route exact path='/reset/password/:token/' component={ResetPassword}></Route>
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}